<template>
  <div class="user-avatar-dropdown">
    <el-dropdown trigger="click" v-if="value">
      <div style="display: flex; align-items: center">
        <!-- v-if="value.headPortrait!=null" -->
        <div v-if="value.headPortrait != null">
          <el-avatar :src="value.headPortrait"></el-avatar>
        </div>
        <div v-else>
          <el-avatar size="large" :src="circleUrl"></el-avatar>
        </div>
        <div class="user-avatar">
          <span style="color: #2c2c2c; font-weight: 400; margin: 0 10px">{{ value.userName }}</span>
          <i :size="18" class="el-icon-arrow-down"></i>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item name="modify" @click.native="personal">个人中心</el-dropdown-item>
        <el-dropdown-item name="modify" @click.native="onlineReserve">在线预约</el-dropdown-item>
        <el-dropdown-item name="logout" @click.native="logout">退出</el-dropdown-item>
        <!-- <el-dropdown-item name="logout11" @click.native="login"
          >登录</el-dropdown-item
        > -->
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <div v-else style="margin: 0 10px;cursor: pointer" @click="login" id="loginBtn"><span>登录</span></div> -->
    <login v-model="loginDialog" ref="empty" />
  </div>
</template>
<script>
import login from "./login.vue";
export default {
  inject: ['reload'],
  components: { login },
  name: "User",
  data() {
    return {
      userName: "",
      loginDialog: false,
      token: '',
      value: '',
      url: '',
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  props: {
    messageUnreadCount: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // this.$nextTick(() => {
    this.token = localStorage.getItem("pc_token")
    this.value = JSON.parse(localStorage.getItem("pc_data"))

    // })   
  },
  watch: {
    "$store.state.value": function () {
      //监听vuex中userName变化而改变header里面的值
      let state = this.$store.state.value;
      console.log(state, 'value值');
      this.value = state
    },

  },
  methods: {
    login() {
      this.loginDialog = true;
      this.$refs.empty.clear();

    },
    updateVisibleId(val) {
      this.loginDialog = val;
    },
    personal() {
      this.$router.push({
        path: '/personal/index'
      })
    },
    onlineReserve() {
      this.$router.push({
        path: '/personal/onlineReserve'
      })
    },
    logout(name) {
      let that = this
      this.$confirm("确定要退出当前用户吗？", "退出用户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // confirmButton回调
        localStorage.clear();
        this.$store.dispatch('setValue', null)
        that.$message.success("退出成功");
        this.reload()

      }).catch(() => {
        // cancelButton回调，把当前页路由推回
        // 不能使用this.$router进行路由操作，会触发beforeRouteLeave导致死循环
        // window.history.pushState("", "", this.currentUrl);
        that.$message.error("退出失败");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-avatar {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

}

.user-avatar-dropdown {
  position: relative;
  left: 15%;
  top: 0;
}
</style>
