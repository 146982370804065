/**
 * @description 
 * @param {boolean} layoutHidden  头部导航栏隐藏 true 显示 false 隐藏
 * @param {boolean} BottomAssembly 底部导航栏隐藏 true 显示 false 隐藏  默认显示
 */

import layoutHeaderAside from '@/layout'
import news from './modules/news'
import activity from './modules/activity'
import live from './modules/live'
import course from './modules/course'
import meet from './modules/meet'
import personal from './modules/personal'
import interactive from './modules/interactive'
import Theonlinetest from './modules/Theonlinetest'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)



/**
 *  页面路由
 */
export const menus = [
  news,
  activity,
  live,
  course,
  meet,
  personal,
  interactive,
  Theonlinetest
]

// 在主框架内显示
const frameIn = [{
  path: '/',
  component: layoutHeaderAside,
  redirect: {
    path: '/index'
  },
  meta: {
    title: '',
    layoutHidden: true
  },
  children: [{
    path: '/index',
    name: 'Index',
    component: _import('Home.vue'),
    meta: {
      title: '',
      layoutHidden: true,
    }
  },{
    path:'/agreement',
    name:'agreement',
    component: _import('agreement.vue'),
    meta: {
      title: '',
      layoutHidden: true,
    }
  },{
    path:'/videoplayerlive',
    name:'videoplayerlive',
    component: _import('live/videoplayerlive.vue'),
    meta: {
      title: '',
      layoutHidden: true,
    }
  }]
}, ]


// 重新组织后导出
export default [
  ...menus,
  ...frameIn
]
