import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);


export default new Vuex.Store({
	state: {
		value:{},
		courseName:''
	},
	mutations: {
		//弹出层状态的改变
		changeValue(state, value) {
			state.value = value
		},
		changeCourseName(state, courseName) {
			state.courseName = courseName
		}
	},
	actions: {
		//使用的异步修改数据仓库
		setValue(context, value) {
			context.commit('changeValue', value)
		},
		//使用的异步修改数据仓库
		setCourseName(context, courseName) {
			context.commit('changeCourseName', courseName)
		}
	},
	getters: {
	} 
})
 