// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
        /**
       * @description 统计所有的提问回复数量
       * @param {Object} data
       */
      getUserAskReply(data = {}) {
        return request({
          url: '/train/trainCourseAskReply/api/getUserAskReply?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询个人互动消息
       * @param {Object} data
       */
      queryUserTrainCourseAskQuestionList(data = {}) {
        return request({
          url: '/train/trainCourseAskQuestion/api/queryUserTrainCourseAskQuestionList?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 删除提问信息
       * @param {Object} data
       */
      deleteCourseAskQuestion(data = {}) {
        return request({
          url: '/train/trainCourseAskQuestion/api/deleteCourseAskQuestion?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 删除回复信息
       * @param {Object} data
       */
      deleteTrainCourseAskReply(data = {}) {
        return request({
          url: '/train/trainCourseAskReply/api/deleteTrainCourseAskReply?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询会员的消息列表
       * @param {Object} data
       */
      queryMemberUserNoticeList(data = {}) {
        return request({
          url: '/base/channelNotice/api/queryMemberUserNoticeList?route=base', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询通知数量
       * @param {Object} data
       */
      getChannelNoticeNum(data = {}) {
        return request({
          url: '/base/channelNotice/api/getChannelNoticeNum?route=base', 
          method: 'post',
          data
        })
      },  
       /**
       * @description 查询通知数量
       * @param {Object} data
       */
        findChannelNoticeById(data = {}) {
          return request({
            url: '/base/channelNotice/api/findChannelNoticeById', 
            method: 'post',
            data
          })
        },  
      /**
       * @description 更新通知为已读
       * @param {Object} data
       */
         updateNoticeRead(data = {}) {
          return request({
            url: '/base/channelNotice/api/updateNoticeRead', 
            method: 'post',
            data
          })
        },  
  })
  