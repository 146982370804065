/*
 * @Description: 
 * @Author: liqin
 * @Date: 2022-05-11 16:23:03
 */
// 功能插件
import pluginApi from '@/plugin/api'
import httpConfig from '@/api/httpConfig';
import util from '@/libs/util.js'
// 组件
import '@/components'
// svg 图标
import '@/assets/svg-icons'

// import 'amfe-flexible';
import 'amfe-flexible/index.js'

import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import '@/assets/style/base.scss'
//  element-ui 自定义主题
// import '@/assets/style/element-variables.scss'

export default {
  async install(Vue, options) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    // https://cn.vuejs.org/v2/api/#productionTip
    Vue.config.productionTip = false
    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV
    Vue.prototype.$http = httpConfig;
    Vue.prototype.$util = util

    // 插件
    Vue.use(pluginApi)
    Vue.use(ElementUI)
  }
}
