import axios from 'axios'
import qs from 'qs'
import {
  Message,
  MessageBox
} from 'element-ui'
import {
  get
} from 'lodash'
// import store from '@/store'
// import util from '@/libs/util.js'
import $ from 'jquery'

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    config => config,
    error => {
      // 发送失败
      console.log(error)
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      const res = response.data

      if (res.code === '10000') {
        return response.data
      } if (res.code === '10008') {
        return response.data
      } else {
        if (res.code === '99999' || res.code === '10002') {
          $("#loginBtn").click()
          localStorage.removeItem("pc_token");    
          localStorage.removeItem("pc_data");    
        }else if (res.code === '10003') {
        }else {
          let _msg = res.msg||'访问异常'
          if(_msg.indexOf('渠道编码为空') != -1){
            window.location.reload()
          }else{
            Message({
              customClass: 'errorMessageCustomClass',
              showClose: true,
              message: res.msg || '访问异常',
              type: 'warning',
              duration: 5 * 1000
            })
          }
          
        }
        // if (!res.msg == 'token参数为空') {
        //   Message({
        //     customClass: 'errorMessageCustomClass',
        //     showClose: true,
        //     message: res.msg || '访问异常',
        //     type: 'warning',
        //     duration: 5 * 1000
        //   })
        // }

        return Promise.reject(res)
      }
    },
    error => {
      const status = get(error, 'response.status')
      switch (status) {
        case 400:
          error.message = '请求错误';
          break
        case 401:
          error.message = '未授权，请登录';
          break
        case 403:
          error.message = '拒绝访问';
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break
        case 408:
          error.message = '请求超时';
          break
        case 500:
          error.message = '服务器内部错误';
          break
        case 501:
          error.message = '服务未实现';
          break
        case 502:
          error.message = '网关错误';
          break
        case 503:
          error.message = '服务不可用';
          break
        case 504:
          error.message = '网关超时';
          break
        case 505:
          error.message = 'HTTP版本不受支持';
          break
        default:
          break
      }
      Message({
        showClose: true,
        message: '访问异常',
        type: 'warning',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  )
  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    const configDefault = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      timeout: 15000,
      baseURL: process.env.VUE_APP_API,
      data: {}
    }
    config.data = qs.stringify(config.data)
    return service(Object.assign(configDefault, config))
  }
}

export const service = createService()
export const request = createRequestFunction(service)
