/*
 * @Description: 
 * @Author: liqin
 * @Date: 2022-05-13 17:22:49
 */
/**
 * @description 
 * @param {boolean} layoutHidden  头部导航栏隐藏 true 显示 false 隐藏
 * @param {boolean} BottomAssembly 底部导航栏隐藏 true 显示 false 隐藏  默认显示
 */
import layoutHeaderAside from '@/layout'


// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const meta = {
  auth: true
}

export default {
  path: '/personal',
  redirect: {
    path: '/personal/index'
  },
  component: layoutHeaderAside,
  children: (pre => [{
    path: `${pre}index`,
    name: `${pre}index`,
    component: _import('personal/index.vue'),
    meta: {
      ...meta,
      title: '个人中心',
      layoutHidden: true
    }
  },
  {
    path: `${pre}onlineReserve`,
    name: `${pre}onlineReserve`,
    component: _import('personal/onlineReserve.vue'),
    meta: {
      ...meta,
      title: '在线预约',
      layoutHidden: true
    }
  },
    // {
    //   path: `${pre}detail`,
    //   name: `${pre}detail`,
    //   component: _import('personal/detail.vue'),
    //   meta: {
    //     ...meta,
    //     title: '新闻列表',
    //     layoutHidden: true
    //   }
    // },

  ])('/personal/')
}

