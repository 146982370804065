// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
    /**
       * @description 获取标签列表
       * @param {Object} data
       */
      getFrontLabelPageApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontLabelPageApi?route=train', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 获取课程详情
       * @param {Object} data
       */
      getCourseDetailApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getCourseDetailApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程讲师列表
       * @param {Object} data
       */
      getFrontCourseTeacherApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontCourseTeacherApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取标签课程列表
       * @param {Object} data
       */
      getFrontCourseStatisticApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontCourseStatisticApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程练习信息
       * @param {Object} data
       */
      getFrontCoursePracticePageApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontCoursePracticePageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询课程提问列表
       * @param {Object} data
       */
      queryCourseAskQuestionList(data = {}) {
        return request({
          url: '/train/trainCourseAskQuestion/api/queryCourseAskQuestionList?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询课程提问列表
       * @param {Object} data
       */
      saveTrainCourseAskQuestion(data = {}) {
        return request({
          url: '/train/trainCourseAskQuestion/api/saveTrainCourseAskQuestion?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询提问回复列表
       * @param {Object} data
       */
      queryTrainCourseAskReplyList(data = {}) {
        return request({
          url: '/train/trainCourseAskReply/api/queryTrainCourseAskReplyList?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 保存回复信息
       * @param {Object} data
       */
      saveTrainCourseAskReply(data = {}) {
        return request({
          url: '/train/trainCourseAskReply/api/saveTrainCourseAskReply?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程试卷信息
       * @param {Object} data
       */
      getCourseExaminationPageApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getCourseExaminationPageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学员笔记管理列表
       * @param {Object} data
       */
      queryTrainCourseNoteList(data = {}) {
        return request({
          url: '/train/trainCourseNote/api/queryTrainCourseNoteList?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程标签
       * @param {Object} data
       */
      getFrontCourseLabelPageApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontCourseLabelPageApi', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程标签
       * @param {Object} data
       */
      getFrontLabelCoursePageApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontLabelCoursePageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 添加课程评价
       * @param {Object} data
       */
      addCourseAppraise(data = {}) {
        return request({
          url: '/train/courseAppraise/api/addCourseAppraise?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取学员是否评论
       * @param {Object} data
       */
      getUserCourseIsAppraise(data = {}) {
        return request({
          url: '/train/courseAppraise/api/getUserCourseIsAppraise?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取培训课程评价列表
       * @param {Object} data
       */
      getCourseAppraiseListApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getCourseAppraiseListApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取培训课程评价列表
       * @param {Object} data
       */
      queryLiveRoomListFront(data = {}) {
        return request({
          url: '/webSocket/liveRoom/api/queryLiveRoomListFront', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学员购买加入课程
       * @param {Object} data
       */
      subTrainCourseOrder(data = {}) {
        return request({
          url: '/train/trainCourseOrder/api/subTrainCourseOrder?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学员购买加入课程提交单据
       * @param {Object} data
       */
      submitTrainCourseOrder(data = {}) {
        return request({
          url: '/train/trainCourseOrder/api/submitTrainCourseOrder?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 刷新 微信、支付宝 订单支付状态
       * @param {Object} data
       */
      completePayState(data = {}) {
        return request({
          url: '/train/trainCourseOrder/api/completePayState?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程字段
       * @param {Object} data
       */
      getFrontCourseField(data = {}) {
        return request({
          url: '/train/courseParam/api/getFrontCourseField?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学员加入课程
       * @param {Object} data
       */
      joinCourse(data = {}) {
        return request({
          url: '/train/courseJoin/api/joinCourse?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程章节信息
       * @param {Object} data
       */
      getFrontCourseChapterApi(data = {}) {
        return request({
          url: '/train/courseFrontApi/api/getFrontCourseChapterApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程资源信息
       * @param {Object} data
       */
      getMyCourseResource(data = {}) {
        return request({
          url: '/train/trainCourse/api/getMyCourseResource?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 保存笔记信息
       * @param {Object} data
       */
      saveTrainCourseNote(data = {}) {
        return request({
          url: '/train/trainCourseNote/api/saveTrainCourseNote?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询消息列表
       * @param {Object} data
       */
      getTrainCourseNewsList(data = {}) {
        return request({
          url: '/train/trainCourseNews/api/getTrainCourseNewsList?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 保存视频消息
       * @param {Object} data
       */
      saveTrainCourseNews(data = {}) {
        return request({
          url: '/train/trainCourseNews/api/saveTrainCourseNews?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 添加硬木设备图片
       * @param {Object} data
       */
       addExperimentEquipmentPic(data = {}) {
        return request({
          url: '/curriculum/experimentEquipmentPic/api/addExperimentEquipmentPic', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 删除硬木设备图片
       * @param {Object} data
       */
       delExperimentEquipmentPic(data = {}) {
        return request({
          url: '/curriculum/experimentEquipmentPic/api/delExperimentEquipmentPic', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 上传base64图片
       * @param {Object} data
       */
       uploadBase64Img(data = {}) {
        return request({
          url: '/file/file/api/base64', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取硬木设备图片列表
       * @param {Object} data
       */
       getExperimentEquipmentPicPage(data = {}) {
        return request({
          url: '/curriculum/experimentEquipmentPic/api/getExperimentEquipmentPicPage', 
          method: 'post',
          data
        })
      },     
      
  })
  