// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
    /**
       * @description 根据id查询信息
       * @param {Object} data
       */
      getArticleInfo(data = {}) {
        return request({
          url: '/base/article/api/getArticleInfo?route=base', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 获取文章分类列表
       * @param {Object} data
       */
      getArticleTypeListApi(data = {}) {
        return request({
          url: '/base/article/api/getArticleTypeListApi?route=base', 
          method: 'post',
          data
        })
      },  
       /**
       * @description 根据id查询信息
       * @param {Object} data
       */
        getChannelArticleInfoApi(data = {}) {
          return request({
            url: '/base/channelArticleApi/api/getChannelArticleInfoApi', 
            method: 'post',
            data
          })
        },  
      /**
         * @description 获取文章列表
         * @param {Object} data
         */
       getChannelArticleByTypeIdApi(data = {}) {
          return request({
            url: '/base/channelArticleApi/api/getChannelArticleByTypeIdApi', 
            method: 'post',
            data
          })
        }, 
          /**
       * @description 获取文章一级分类列表
       * @param {Object} data
       */
      getOneLevelArticleTypeApi(data = {}) {
        return request({
          url: '/base/channelArticleApi/api/getOneLevelArticleTypeApi', 
          method: 'post',
          data
        })
      },  
  })
  