// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
    /**
       * @description 查询单个考试试题信息
       * @param {Object} data
       */
      findUserExaminationPapersInfoApi(data = {}) {
        return request({
          url: '/examPaper/examinationUserPapersApi/api/findUserExaminationPapersInfoApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 查询学员考试试题信息
       * @param {Object} data
       */
      findExaminationUserPapersApi(data = {}) {
        return request({
          url: '/examPaper/examinationUserPapersApi/api/findExaminationUserPapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 试卷点击开始考试
       * @param {Object} data
       */
      startUserExaminationPapersApi(data = {}) {
        return request({
          url: '/examPaper/examinationUserPapersApi/api/startUserExaminationPapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 考试保存问题提交
       * @param {Object} data
       */
      saveExaminationUserOptionApi(data = {}) {
        return request({
          url: '/examPaper/examinationUserPapersApi/api/saveExaminationUserOptionApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 考试提交试卷
       * @param {Object} data
       */
      submitExaminationUserPapersApi(data = {}) {
        return request({
          url: '/examPaper/examinationUserPapersApi/api/submitExaminationUserPapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询单个练习试题信息
       * @param {Object} data
       */
      findUserPracticePapersInfoApi(data = {}) {
        return request({
          url: '/examPaper/practiceUserPapersApi/api/findUserPracticePapersInfoApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 试题点击开始练习
       * @param {Object} data
       */
      startUserPracticePapersApi(data = {}) {
        return request({
          url: '/examPaper/practiceUserPapersApi/api/startUserPracticePapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 练习保存问题提交
       * @param {Object} data
       */
      savePracticeUserOptionApi(data = {}) {
        return request({
          url: '/examPaper/practiceUserPapersApi/api/savePracticeUserOptionApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 提交练习
       * @param {Object} data
       */
      submitPracticeUserPapersApi(data = {}) {
        return request({
          url: '/examPaper/practiceUserPapersApi/api/submitPracticeUserPapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询单个学员练习信息
       * @param {Object} data
       */
      findPracticeUserPapersApi(data = {}) {
        return request({
          url: '/examPaper/practiceUserPapersApi/api/findPracticeUserPapersApi?route=examPaper', 
          method: 'post',
          data
        })
      },  
  })
