<!--
 * @Description: 
 * @Author: liqin
 * @Date: 2021-09-26 10:48:48
-->
<template>
  <div
    id="app_main"
    :style="'height:' + innerHeight1 + 'px;width: ' + innerW + 'px;'"
  >
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  // components: { login },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      innerHeight1: null,
      innerW: null,
      isRouterAlive: true,
      domainName:'',
      loginDialog: this.$store.getters.getLogin || false,
    };
  },
  created() {},
  mounted() { 
    this.innerHeight1 = window.innerHeight;
    this.innerW = window.innerWidth;
    this.resize();
    this.onScroll();
    // window.onresize = () => {
    //   this.innerHeight1 = window.innerHeight
    // }
    window.addEventListener("resize", () => {
      this.resize();
      this.onScroll();
    });
    this.domainName = window.location.hostname
    localStorage.setItem('domainName',window.location.hostname)
    this.getAppByDomainNameApi();
  },
  computed: {},
  watch: {
    // loginDialog: {
    //   handler: function (val, oldVal) {
    //     console.log(val,oldVal,"=============")
    //     if (val) {

    //       this.loginDialog = true;
    //     }
    //   },
    //   // 深度观察监听
    //   deep: true,
    // },
    // "$store.getters.getLogin": {
    //   handler: function (newVal, oldVal) {
    //     console.log("变化后的值", newVal);
    //     console.log("变化前的值", oldVal);
    //     this.loginDialog = newVal;
    //     //...你想要操作的逻辑
    //   },
    //   deep: true,
    // },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
     //  域名接口
    getAppByDomainNameApi(){
      // this.$api.getAppByDomainNameApi({
      //   domainName:this.domainName
      //   // domainName:'sny.acoer.cn'
      // }).then(res => {
      //   if(res.code == 10000){
      //     localStorage.setItem('channelId',res.data.channelId)
      //     window.VUE_APP_TITLE = res.data.channelName
      //   }
      // })
      localStorage.setItem('channelId',"NJGS")
    },
    resize() {
      this.innerHeight1 = window.innerHeight;

      const html = document.querySelector("html");
      if (window.innerWidth <= 1200 && window.innerWidth >= 768) {
        // console.log(html);
        //  html.style.fontSize = '150px'
        this.innerW = window.innerWidth;
      } else if (window.innerWidth >= 768) {
        this.innerW = window.innerWidth;
      } else {
        this.innerW = window.innerWidth;
      }
    },
    onScroll() {
      const app_main = document.querySelector("#app_main");
      if (app_main.scrollHeight > window.innerHeight) {
        this.innerW = this.innerW - 6;
      }
    },
  },
};
</script>
<style>
</style>
