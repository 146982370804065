<!--
 * @Description: 底部组件
 * @Author: liqin
 * @Date: 2022-05-18 16:03:27
-->
<template>
<!--  style="margin-top: 5%;" -->
  <div class="textCenter" :style="{backgroundImage:'url('+footerBgImg+')'}">
    <div v-for="(item,index) in navigation" :key="index" >
      <div  v-html="item.footContent"></div>     
    </div>
     <!-- <span>备案号： 技术支持： <span style="text-decoration:underline;" v-html="item.footContent"></span></span> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      navigation:{},
      footerBgImg:''
    }
  },
  mounted(){
    this.getAppFootNavigationApi()
    this.getAppAdContentApiFb()
  },
  methods:{
    getAppAdContentApiFb() {
      this.$api
        .selectContentList({
          channelId: this.$http.channelId,
          token: this.token,
          codes: "000003",
        })
        .then((res) => {
          if (res.code == 10000) {
            this.footerBgImg = res.data[0].adImg
          }
        });
    },
    getAppFootNavigationApi(){
      this.$api.getAppFootNavigationApi({
        channelId: this.$http.channelId
      }).then(res => {
        if(res.code == 10000){
          this.navigation = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
.textCenter {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 14px;
  // background-color: #333;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .textCenter {
    font-size: 3.7333vw !important;
    padding-top: 2.6667vw;
    > div {
      line-height: 4.8vw;
    }
  }
  ::v-deep span {
      span {
        display: block !important;
      }
    }
}
</style>