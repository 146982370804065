var length =120;

function countdown(that) {
  var seconds = that.seconds;
  var sendCodeLabel = that.sendCodeLabel;
  if (seconds <= 1) {
    sendCodeLabel = '发送验证码';
    seconds = length;
    that.sendCodeBtnDisabled = false;
    clearInterval(that.interval);
  } else {
    sendCodeLabel = --seconds + 's后重发'
  }
  that.seconds = seconds;
  that.sendCodeLabel = sendCodeLabel;
}

module.exports = {
  countdown: countdown,
  length: length
}