/*
 * @Description: 
 * @Author: liqin
 * @Date: 2022-05-13 17:22:49
 */
/**
 * @description 
 * @param {boolean} layoutHidden  头部导航栏隐藏 true 显示 false 隐藏
 * @param {boolean} BottomAssembly 底部导航栏隐藏 true 显示 false 隐藏  默认显示
 */
import layoutHeaderAside from '@/layout'


// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const meta = {
  auth: true
}

export default {
  path: '/Theonlinetest',
  redirect: {
    path: '/Theonlinetest'
  },
  component: layoutHeaderAside,
  children: (pre => [{
      path: `${pre}TestPaper/viewItem`,
      name: `TestPaper`,
      component: _import('Theonlinetest/TestPaper/viewItem.vue'),
      meta: {
        ...meta,
        title: '考试试卷',
        layoutHidden: true
      }
    },{
      path: `${pre}PracticeTests/viewItem`,
      name: `PracticeTests`,
      component: _import('Theonlinetest/PracticeTests/viewItem.vue'),
      meta: {
        ...meta,
        title: '练习试卷',
        layoutHidden: true
      }
    },{
      path: `${pre}TestView/examinationPapersInfoErr`,
      name: `TestView`,
      component: _import('Theonlinetest/TestView/examinationPapersInfoErr.vue'),
      meta: {
        ...meta,
        title: '考试查看',
        layoutHidden: true
      }
    },{
      path: `${pre}PracticeView/practicePapersInfoErr`,
      name: `PracticeView`,
      component: _import('Theonlinetest/PracticeView/practicePapersInfoErr.vue'),
      meta: {
        ...meta,
        title: '练习查看',
        layoutHidden: true
      }
    },
  ])('/Theonlinetest/')
}

