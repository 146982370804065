/*
 * @Description: 
 * @Author: liqin
 * @Date: 2021-09-26 10:48:48
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/libs/util.js'
// 路由数据
import routes from './routes'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

/**
 * @description 创建路由
 * @param {Array} routes 路由设置
 */
const createRouter = (routes = []) => new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  mode: 'hash',
  routes
})

// 导出路由 在 main.js 里使用
const router = createRouter(routes)

/**
 * @description 重新设置路由
 * @param {Array} routes 额外追加的路由
 */
export function resetRouter(routes = []) {
  router.matcher = createRouter(routes).matcher
}



router.afterEach(to => {
  // 更改标题
  util.title(to.meta.title)
})



export default router
