/*
 * @Description: 
 * @Author: liqin
 * @Date: 2021-09-26 10:48:48
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 核心插件
import core from '@/plugin'
import ElementUI from 'element-ui'
// import VideoPlayer from 'vue-video-player'
// require('vue-video-player/node_modules/video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)
// el-dialog拖拽
import '@/utils/directives'
// 安装 ElementUI（ui）
Vue.use(ElementUI)

//倒计时
import vueTimerCountdown from 'vue-timer-countdown'
Vue.use(vueTimerCountdown, { component: 'TimerCountdown' })

//掉接口地址
import global  from './components/common';
Vue.prototype.$Global = global
import Qs from 'qs'
Vue.prototype.$qs = Qs;
//引入layer弹出层
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
Vue.prototype.$layer = layer(Vue)

window.VUE_APP_TITLE = ''

// 核心插件
Vue.use(core)



Vue.prototype.$hub = new Vue()





new Vue({
  router,
  store,
  data() {
    return {
    }
  },
  render: h => h(App)
}).$mount('#app')
