// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
      /**
       * @description 查询直播间信息详情（无需登录token）
       * @param {Object} data
       */
       queryLiveRoomDetailApi(data = {}) {
        return request({
          url: '/webSocket/webSocketApi/api/queryLiveRoomDetailApi', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 查询直播间信息详情（需要登录）
       * @param {Object} data
       */
       queryLiveRoomDetail(data = {}) {
        return request({
          url: '/webSocket/liveRoom/api/queryLiveRoomDetail', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学生加入直播间。
       * @param {Object} data
       */
       insertLiveJoinPerson(data = {}) {
        return request({
          url: '/webSocket/liveJoinPerson/api/insertLiveJoinPerson', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学生退出直播间。
       * @param {Object} data
       */
       outLiveJoinPerson(data = {}) {
        return request({
          url: '/webSocket/liveJoinPerson/api/outLiveJoinPerson', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取直播间在线人数
       * @param {Object} data
       */
       getLiveRomeOnLinePerson(data = {}) {
        return request({
          url: '/webSocket/liveJoinPerson/api/getLiveRomeOnLinePerson', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 直播间发送聊天消息
       * @param {Object} data
       */
       updateLiveChat(data = {}) {
        return request({
          url: '/webSocket/liveChat/api/updateLiveChat', 
          method: 'post',
          data
        })
      },  
     
     
      
  })
  