<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="24" class="hidden-xs-only">
        <div class="bgwhite" :style="{ backgroundImage: 'url(' + topBgImg + ')' }">
          <div class="topHeader">
            <div class="logo_search_bar">
              <div class="logo">
                <img :src="channelLogo" @click="$router.push('/index')" alt="" style="height: 47px" />
              </div>
              <div class="search_bar">
                <el-input placeholder="" v-model="search" class="input-with-select" style=""
                  suffix-icon="el-icon-search">
                </el-input>
              </div>
            </div>
          </div>

          <!-- <div class="header betweenJustify" v-if="$route.meta.layoutHidden"> -->
          <!-- <div class="logo">
              <img
                :src="channelLogo"
                @click="$router.push('/index')"
                alt=""
                style="height: 50px"
              />
            </div> -->
          <!-- 顶部导航菜单 -->
          <!-- <div class="flex_main">
              <menuheader></menuheader>
            </div> -->
          <!-- 输入框 -->
          <!-- <div class="headerR">
              <div>
                <el-input
                  placeholder="请输入内容"
                  v-model="search"
                  class="input-with-select"
                  style=""
                >
                  <el-select
                    v-model="select"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option label="课程11111" value="1"></el-option> -->
          <!-- <el-option label="培训" value="2"></el-option>
                    <el-option label="直播" value="3"></el-option>
                    <el-option label="会议" value="4"></el-option> -->
          <!-- </el-select>
                  <el-button
                    slot="append"
                    @click="typeTarget"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </div> -->
          <!-- <div class="search">
                <el-row :gutter="0">
                  <el-col :span="8" :offset="0">
                    <el-select class="select" v-model="select" placeholder="请选择">
                      <el-option label="课程" value="1"></el-option>
                      <el-option label="培训" value="2"></el-option>
                      <el-option label="直播" value="3"></el-option>
                      <el-option label="会议" value="4"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="16" :offset="0">
                    <el-input class="input" v-model="search" suffix-icon="el-icon-search" placeholder="请输入搜索内容....."></el-input>
                  </el-col>
                </el-row>
              </div> -->
          <!-- </div> -->
          <!-- <div style="margin-left: 1%">
              <el-badge :value="count">
                <a style="position: relative; cursor: pointer" @click="message">
                  <img class="imgs" src="@/assets/images/header/11.png" />
                </a>
              </el-badge>
            </div> -->
          <div>
            <user />
          </div>
          <!-- </div> -->
        </div>
        <div class="menu_header">
          <menuheader></menuheader>
        </div>
      </el-col>
      <el-col :span="24" class="hidden-sm-and-up">
        <div class="betweenJustify bgfff h5Nav fsize14" v-if="$route.meta.layoutHidden">
          <div class="logo">
            <img src="images/logo.png" @click="$router.push('/index')" alt="" />
          </div>
          <div class="">
            <!-- <i class="el-icon-bell fsize16"></i> -->
            <!-- <span class="logon">登录</span> -->
            <el-popover placement="bottom" width="80" trigger="click" style="" popper-class="popoverWidth80">
              <router-link class="linkColor" to="/index">首页</router-link>
              <router-link class="linkColor mt10" to="/news/index">新闻资讯</router-link>
              <router-link class="linkColor mt10" to="/activity/index">培训活动</router-link>
              <router-link class="linkColor mt10" to="/live/index">直播活动</router-link>
              <router-link class="linkColor mt10" to="/course/index">在线课程</router-link>
              <router-link class="linkColor mt10" to="/meet/index">在线会议</router-link>
              <span slot="reference">
                <span class="el-icon-menu fsize16"></span>
                <span class="menuNav">导航</span>
              </span>
            </el-popover>
          </div>
        </div>
      </el-col>


    </el-row>

    <keep-alive :include="keepAlive">
      <router-view />
    </keep-alive>
    <!-- v-if="layoutBottomHidden" -->
    <div :class="isBottomAssembly ? 'BottomAssembly' : ''">
      <BottomAssembly v-if="$route.meta.BottomAssembly !== false"></BottomAssembly>
    </div>
    <!-- 占位盒子 -->
    <div class="h60px" v-if="isBottomAssembly"></div>
  </div>
</template>

<script>
import menuheader from "./header-aside/menu-header.vue";
import BottomAssembly from "@/components/BottomAssembly";
import User from "@/components/user";
import $ from "jquery";
export default {
  components: { menuheader, BottomAssembly, User },
  data() {
    return {
      token: localStorage.getItem("pc_token") ?? "",
      keepAlive: [],
      select: "1",
      search: "",
      isBottomAssembly: false,
      // layoutBottomHidden:true,
      channelLogo: '',
      count: "",
      topBgImg: '',
    };
  },
  mounted() {
    // if(this.$route.meta.layoutBottomHidden){
    //   this.layoutBottomHidden =false
    // }else{
    //   this.layoutBottomHidden = true
    // }
    // console.log(this.layoutBottomHidden);
    this.onScroll();
    // this.getAppChannelApi();
    if (this.token != '') {
      this.getUnReadNoticeCount();
    }
    // else{
    //   console.log(this.token);
    //   this.count=''
    // }
    this.getAppAdContentApiT()
    this.$api.getAppByChannelApi({channelId: this.$http.channelId}).then(res=>{
      this.channelLogo = res.data.channelLogo
    })
  },
  updated() {
    this.onScroll();
  },
  created() { },
  watch: {
    //此时我监听的是对象，当$store.state.userInfo.Name发生修改时，此时需要深度监听才能监听到数据变化
    "$store.state.courseName": {
      deep: true, //深度监听设置为 true
      handler: function (newVal, oldVal) {
        // console.log("数据发生变化啦");  //修改数据时，能看到输出结果
        // console.log(newVal,oldVal);
        this.search = newVal;
      },
    },
  },
  methods: {

    typeTarget() {
      if (this.search != "") {
        this.$store.dispatch("setCourseName", this.search);
        this.$router.push({
          path: "/course/index",
          query: {
            curriculumOrExperimentName: this.search,
          },
        });
      } else {
        this.$router.push({
          path: "/course/index",
        });
      }
    },
    message() {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      } else {
        this.$router.push({
          path: "/interactive/index",
        });
      }
    },
    getAppChannelApi() {
      this.$api
        .getAppChannelApi({
          channelId: this.$http.channelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.channelLogo = res.data.channelLogo;
          }
        });
    },
    getUnReadNoticeCount() {
      this.$api
        .getUnReadNoticeCount({
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.count = res.data.unReadCount;
          }
        });
    },
    onScroll() {
      const app_main = document.querySelector("#app_main");
      if (app_main.scrollHeight > window.innerHeight) {
        this.isBottomAssembly = false;
      } else {
        this.isBottomAssembly = true;
      }
    },
    getAppAdContentApiT() {
      this.$api
        .selectContentList({
          channelId: this.$http.channelId,
          token: this.token,
          codes: "000002",
        })
        .then((res) => {
          if (res.code == 10000) {
            console.log(434, res.data)
            if (res.data[0].adImg) {
              this.topBgImg = res.data[0].adImg
              this.$forceUpdate()
            } else {
              this.topBgImg = '../assets/images/home/topHead.png'
            }
            // 
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fsize16 {
  font-size: 24px !important;
  margin-left: 10px;
}

.header {
  max-width: 1400px;
  margin: 0 auto;
  height: 70px;
}

.logo {
  cursor: pointer;

  img {
    object-fit: cover;
  }
}

.bgwhite {
  width: 100%;
  background-image: url('../assets/images/home/topHead.png');
  background-size: 100% 100%;
  height: 93px;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ccc;
}

.logo_search_bar {
  max-width: 1490px;
  margin: 0 auto;
  height: 93px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .search_bar {
    position: absolute;
    right: 130px;
  }

  .input-with-select ::v-deep .el-input__inner {
    border-radius: 50px;
    width: 262px;
    height: 30px;
    line-height: 30px;
    background: transparent;
    border: 0.5px solid rgba(220, 220, 220, 0.2);

  }

  ::v-deep .el-input__icon {
    line-height: 30px;
  }
}

.menu_header {
  width: 100%;
  // height: 50px;
  // line-height: 50px;
  background-color: #fff;
}

.headerR {
  // width: 384px;
}

.search {
  // width: 294px;
}

// ::v-deep .el-input__inner {
//   border: none !important;
//   width: 100%;
//   background: #f8f8f8;
// }
// ::v-deep .select {
//   display: inherit !important;
// }
.logon_box {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
}

.logon {
  position: relative;
  top: -5px;
  margin-left: 10px;
}

.BottomAssembly {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  // margin-top: 5%;
}

.h60px {
  height: 60px;
}

.h5Nav {
  padding: 1.0667vw 2vw;
  color: #666;

  .el-icon-menu {
    position: relative;
    top: -1px;
  }

  .menuNav {
    position: relative;
    top: -5px;
    left: -6px;
    margin-left: 10px;
  }

  .logo {
    img {
      width: 16vw;
    }
  }
}

.linkColor {
  color: #666;
  display: block;
}

::v-deep .el-select .el-input {
  width: 80px;
}

.headerR ::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  // border: 1px solid #fff;
}

.headerR ::v-deep .el-input-group__append {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  // border: 1px solid #fff;
}

.headerR ::v-deep .el-input-group--append .el-input__inner {
  // border: 1px solid #fff;
}

.imgs {
  width: 25px;
  height: 25px;
}
</style>
<style>
.popoverWidth80 {
  min-width: 100px !important;
}

/* .footerlink{
  background-image: url('../assets/images/home/footerlink.png');
  background-size: 100% 100%;
  max-width: 1400px;
  margin: -20px auto 50px auto;
  height: 100px;
} */
</style>