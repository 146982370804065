/*
 * @Description: api
 * @Author: liqin
 * @Date: 2021-09-04 10:38:34
 */
export default {
  httpConfig: process.env.VUE_APP_API, // 公共
  exportBatchReport: '/curriculum/curriculumApi/api/exportBatchReport?route=curriculum',//导出实验报告
  authImage: '/auth/auth/api/authImage?route=auth&t=',//图形验证码
  uploadImg: '/file/file/api/uploadImg?route=file', // 图片上传
  wss:'wss:/t.acoer.cn//ws/webSocket/websocket/',
  channelId: localStorage.getItem("channelId"),//默认渠道
  uploadImgNodeId: '/file/file/api/single?route=file',//上传附件返回nodeid
  getHardwareId: 'http://127.0.0.1:8002/EMOOC/hardware_id',//获取硬木设备序列号
  baseUrl: 'http://zhjx.zjhu.edu.cn',
}
