// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
    /**
       * @description 获取课程实验信息
       * @param {Object} data
       */
     getClientCurriculumExperimentInfoApi(data = {}) {
        return request({
          url: '/curriculum/curriculumResourceApi/api/getClientCurriculumExperimentInfoApi', 
          method: 'post',
          data
        })
      },  
    /**
       * @description 获取课件列表信息
       * @param {Object} data
       */
     getClientCurriculumCoursewareListApi(data = {}) {
        return request({
          url: '/curriculum/curriculumResourceApi/api/getClientCurriculumCoursewareListApi', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程练习信息
       * @param {Object} data
       */
      getFrontCurriculumPracticeListApi(data = {}) {
        return request({
          url: '/curriculum/curriculumPracticeApi/api/getFrontCurriculumPracticeListApi', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取课程考试信息
       * @param {Object} data
       */
       getFrontCurriculumExaminationListApi(data = {}) {
        return request({
          url: '/curriculum/curriculumExaminationApi/api/getFrontCurriculumExaminationListApi', 
          method: 'post',
          data
        })
      },  
       /**
       * @description 获取课程直播间
       * @param {Object} data
       */
        getFrontCurriculumLiveRoomListApi(data = {}) {
          return request({
            url: '/webSocket/curriculumLiveRoomApi/api/getFrontCurriculumLiveRoomListApi', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取课程课前导学
       * @param {Object} data
       */
        getClientCoursePreGuidanceApi(data = {}) {
          return request({
            url: '/curriculum/curriculumResourceApi/api/getClientCoursePreGuidanceApi', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取课程作业列表
       * @param {Object} data
       */
        getClientCurriculumUserHomeworkListApi(data = {}) {
          return request({
            url: '/curriculum/curriculumResourceApi/api/getClientCurriculumUserHomeworkListApi', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取课程课前预习
       * @param {Object} data
       */
        getFrontCoursePreGuidance(data = {}) {
          return request({
            url: '/base/notice/api/getFrontCoursePreGuidance', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取课程提问列表
       * @param {Object} data
       */
        getClientCurriculumQuestionAnswerListApi(data = {}) {
          return request({
            url: '/curriculum/curriculumResourceApi/api/getClientCurriculumQuestionAnswerListApi', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取课程提问列表
       * @param {Object} data
       */
        queryCurriculumQuestionAnswerList(data = {}) {
          return request({
            url: '/curriculum/curriculumQuestionAnswer/api/queryCurriculumQuestionAnswerList', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取阿里sts服务器Token
       * @param {Object} data
       */
        getStsToken(data = {}) {
          return request({
            url: '/file/file/api/getStsToken', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 添加阿里sts附件信息
       * @param {Object} data
       */
        addNodeInfo(data = {}) {
          return request({
            url: '/file/file/api/addNodeInfo', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 获取附件信息
       * @param {Object} data
       */
        getNodeInfo(data = {}) {
          return request({
            url: '/file/file/api/getNodeInfo', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 上传附件返回nodeid
       * @param {Object} data
       */
        uploadImgNodeId(data = {}) {
          return request({
            url: '/file/file/api/single', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 回复内容
       * @param {Object} data
       */
        updateCurriculumQuestionReply(data = {}) {
          return request({
            url: '/curriculum/curriculumQuestionReply/api/updateCurriculumQuestionReply', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 发表内容
       * @param {Object} data
       */
        updateCurriculumQuestionAnswer(data = {}) {
          return request({
            url: '/curriculum/curriculumQuestionAnswer/api/updateCurriculumQuestionAnswer', 
            method: 'post',
            data
          })
        },  
       /**
       * @description 查询报告详情
       * @param {Object} data
       */
        queryExperimentReportInfo(data = {}) {
          return request({
            url: '/curriculum/experimentReport/api/queryExperimentReportInfo', 
            method: 'post',
            data
          })
        }, 
         /**
       * @description 保存实验报告
       * @param {Object} data
       */
        updateUserReport(data = {}) {
            return request({
              url: '/curriculum/userReport/api/updateUserReport', 
              method: 'post',
              data
            })
        },   
         /**
       * @description 保存实验报告步骤
       * @param {Object} data
       */
       updateUserReportStep(data = {}) {
            return request({
              url: '/curriculum/userReportStep/api/updateUserReportStep', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 保存当前会员和实验查询保存的视频观看信息
       * @param {Object} data
       */
        saveCurriculumExperimentLookVideoByToken(data = {}) {
            return request({
              url: '/curriculum/experimentLookVideo/api/saveCurriculumExperimentLookVideoByToken', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 保存实验报告步骤
       * @param {Object} data
       */
        findCurriculumExperimentLookVideoByToken(data = {}) {
            return request({
              url: '/curriculum/experimentLookVideo/api/findCurriculumExperimentLookVideoByToken', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 获取会员加入班级列表
       * @param {Object} data
       */
        getExperimentJoinClassList(data = {}) {
            return request({
              url: '/curriculum/examinationJoin/api/getExperimentJoinClassList', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 查询课件在线答题列表
       * @param {Object} data
       */
         findCurriculumExaminationByUser(data = {}) {
            return request({
              url: '/curriculum/curriculumUserExamination/api/findCurriculumExaminationByUser', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 学员上传作业
       * @param {Object} data
       */
         updateUserHomework(data = {}) {
            return request({
              url: '/curriculum/curriculumUserHomework/api/updateUserHomework', 
              method: 'post',
              data
            })
        },   
        /**
       * @description 获取实验是否有报告
       * @param {Object} data
       */
        getIsHasExperimentReportApi(data = {}) {
            return request({
              url: '/curriculum/curriculumApi/api/getIsHasExperimentReportApi', 
              method: 'post',
              data
            })
        },   
    
  })
  