// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
  service,
  request
}) => ({
  /**
   * @description 根据旧密码修改新密码
   * @param {Object} data
   */
  updatePwdByOldPwd(data = {}) {
    return request({
      url: '/member/memberUser/api/updatePwdByOldPwd?route=member',
      method: 'post',
      data
    })
  },
  /**
   * @description 申请充值
   * @param {Object} data
   */
  saveLiveTopUpLog(data = {}) {
    return request({
      url: '/webSocket/liveTopUpLog/api/saveLiveTopUpLog?route=webSocket',
      method: 'post',
      data
    })
  },
  /**
   * @description 查询可充值的列表
   * @param {Object} data
   */
  queryLiveTopUpListIsEnable(data = {}) {
    return request({
      url: '/webSocket/liveTopUp/api/queryLiveTopUpListIsEnable?route=webSocket',
      method: 'post',
      data
    })
  },
  /**
   * @description 刷新 微信、支付宝 订单支付状态
   * @param {Object} data
   */
  completePayState(data = {}) {
    return request({
      url: '/webSocket/liveTopUpLog/api/completePayState?route=webSocket',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改会员信息
   * @param {Object} data
   */
  updateMemberUser(data = {}) {
    return request({
      url: '/member/memberSchoolUsers/api/updateMemberUser?route=member',
      method: 'post',
      data
    })
  },
  /**
   * @description 会员积分日志
   * @param {Object} data
   */
  queryUserPointsLogList(data = {}) {
    return request({
      url: '/member/memberPointsChangeLog/api/queryUserPointsLogList?route=member',
      method: 'post',
      data
    })
  },
  /**
   * @description 查询可考考试试卷列表
   * @param {Object} data
   */
  queryWaitExaminationPapersListApi(data = {}) {
    return request({
      url: '/examPaper/examinationUserPapersApi/api/queryWaitExaminationPapersListApi?route=examPaper',
      method: 'post',
      data
    })
  },
  /**
   * @description 查询学员已做考试
   * @param {Object} data
   */
  queryExaminationUserPapersListApi(data = {}) {
    return request({
      url: '/examPaper/examinationUserPapersApi/api/queryExaminationUserPapersListApi?route=examPaper',
      method: 'post',
      data
    })
  },
  /**
   * @description 查询学员已做练习
   * @param {Object} data
   */
  queryPracticeUserPapersListApi(data = {}) {
    return request({
      url: '/examPaper/practiceUserPapersApi/api/queryPracticeUserPapersListApi?route=examPaper',
      method: 'post',
      data
    })
  },
  /**
   * @description 查询已支付课程单据
   * @param {Object} data
   */
  queryCourseOrderList(data = {}) {
    return request({
      url: '/train/trainCourseOrder/api/queryCourseOrderList?route=train',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取我的证书
   * @param {Object} data
   */
  getMyCertificate(data = {}) {
    return request({
      url: '/train/activityCertificate/api/getMyCertificate?route=train',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取我的活动
   * @param {Object} data
   */
  getMyActivityList(data = {}) {
    return request({
      url: '/train/trainActivity/api/getMyActivityList?route=train',
      method: 'post',
      data
    })
  },
  /**
   * @description 所参与的会议列表（学生前端使用，需要登录）
   * @param {Object} data
   */
  queryMeetingRoomListFront(data = {}) {
    return request({
      url: '/webSocket/meetingRoom/api/queryMeetingRoomListFront',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取我的课程列表
   * @param {Object} data
   */
  getMyCourseList(data = {}) {
    return request({
      url: '/train/trainCourse/api/getMyCourseList?route=train',
      method: 'post',
      data
    })
  },
  /**
  * @description 获取我的课程实验
  * @param {Object} data
  */
  getClientUserCurriculumExperimentList(data = {}) {
    return request({
      url: '/curriculum/curriculumExperiment/api/getClientUserCurriculumExperimentList',
      method: 'post',
      data
    })
  },
  /**
  * @description 修改手机号
  * @param {Object} data
  */
  updateUserPhone(data = {}) {
    return request({
      url: '/member/memberSchoolUsers/api/updateUserPhone',
      method: 'post',
      data
    })
  },
  /**
  * @description 校验短信验证码接口
  * @param {Object} data
  */
  checkSmsCode(data = {}) {
    return request({
      url: '/auth/auth/api/checkSmsCode',
      method: 'post',
      data
    })
  },
  /**
  * @description pc端账修改密码
  * @param {Object} data
  */
  doUpdatePwd(data = {}) {
    return request({
      url: '/member/memberUser/api/doUpdatePwd',
      method: 'post',
      data
    })
  },
  /**
  * @description 发送短信验证码
  * @param {Object} data
  */
  doSendSms(data = {}) {
    return request({
      url: '/auth/auth/api/doSendSms',
      method: 'post',
      data
    })
  },
  /**
* @description 获取整间教室 教室
* @param {Object} data
*/

  queryLabortoryList(data = {}) {
    return request({
      url: '/stadium/laboratory/api/queryLabortoryList?routh=curriculum',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取整间预约-开始时间-结束时间
   * @param {Object} data
   */

  getLaboratoryWorkTimeInfo(data = {}) {
    return request({
      url: '/stadium/laboratory/api/getLaboratoryWorkTimeInfo?route=curriculum',
      method: 'post',
      data
    })
  },/**
  * @description 获取整间教室 教室List
  * @param {Object} data
  */
  queryBaseRemarkList2(data = {}) {
    return request({

      url: '/stadium/baseRemark/api/queryBaseRemarkList',
      method: 'post',
      data
    })
  },
  queryLabortoryListForAppoint(data = {}) {
    return request({
      url: '/stadium/laboratory/api/queryLabortoryListForAppoint?route=curriculum',
      method: 'post',
      data
    })
  },
  findLaboratoryByIdApi(data = {}) {
    return request({
      url: '/curriculum/curriculumStudy/api/getBackCourseScheduleByWeek',
      method: 'post',
      data
    })
  },
  /**
     * @description 获取整间教室 选择会员
     * @param {Object} data
     */

  queryMemberSchoolUserList(data = {}) {
    return request({
      url: '/member/memberSchoolUsers/api/queryMemberSchoolUserList?route=member',
      method: 'post',
      data
    })
  },
  /**
   * @description 整间教室--> 预约
   * @param {Object} data
   */

  addLaboratoryOrderBatchBack(data = {}) {
    return request({
      url: '/stadium/laboratoryOrder/api/addLaboratoryOrderBatchBack?route=stadium',
      method: 'post',
      data
    })
  },
  /**
   * @description 整间教室--> 取消预约
   * @param {Object} data
   */

  laboratoryCancelOrder(data = {}) {
    return request({
      url: '/stadium/laboratoryOrder/api/laboratoryCancelOrder?route=stadium',
      method: 'post',
      data
    })
  },


})
