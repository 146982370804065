/*
 * @Description: 
 * @Author: liqin
 * @Date: 2022-05-16 16:59:13
 */
import Vue from 'vue'

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./icons', false, /\.svg$/)
const iconMap = requireAll(req)
console.log(iconMap);

Vue.prototype.$IconSvg = iconMap.map(e => e.default.id.slice(3))
