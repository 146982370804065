/**
 * @description 更新标题
 * @param {String} title 标题
 */
const util = {}
util.title = function (titleText) {
  setTimeout(() => { 
    console.log(window.VUE_APP_TITLE,"=======================1234556677");
    const processTitle = window.VUE_APP_TITLE || ''
    window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
    }, 300)
  // const processTitle = process.env.VUE_APP_TITLE || ''
  // window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

export default util
