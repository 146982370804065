// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
    service,
    request
  }) => ({
      /**
       * @description 获取标签列表
       * @param {Object} data
       */
      getFrontTrainLabelPageApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getFrontTrainLabelPageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取活动详情
       * @param {Object} data
       */
      getActivityDetailApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getActivityDetailApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取培活动讲师列表
       * @param {Object} data
       */
      getFrontActivityTeacherApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getFrontActivityTeacherApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取活动标签列表
       * @param {Object} data
       */
      getFrontActivityLabelPageApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getFrontActivityLabelPageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取标签活动列表
       * @param {Object} data
       */
      getFrontLabelActivityPageApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getFrontLabelActivityPageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取会员是否评论活动
       * @param {Object} data
       */
      getUserActivityIsAppraise(data = {}) {
        return request({
          url: '/train/activityAppraise/api/getUserActivityIsAppraise?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取活动评论信息
       * @param {Object} data
       */
      getFrontActivityAppraisePageApi(data = {}) {
        return request({
          url: '/train/activityFrontApi/api/getFrontActivityAppraisePageApi?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 添加活动评价
       * @param {Object} data
       */
      addActivityAppraise(data = {}) {
        return request({
          url: '/train/activityAppraise/api/addActivityAppraise?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 获取活动字段信息
       * @param {Object} data
       */
      getFrontActivityField(data = {}) {
        return request({
          url: '/train/activityParam/api/getFrontActivityField?route=train', 
          method: 'post',
          data
        })
      },  
      /**
       * @description 学员活动报名
       * @param {Object} data
       */
      activityEnroll(data = {}) {
        return request({
          url: '/train/activityEnroll/api/activityEnroll?route=train', 
          method: 'post',
          data
        })
      },  
      
  })
  