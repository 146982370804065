
<script>
import Vue from 'vue'
import axios from 'axios'
import toast from './toast/index'
Vue.use(toast)
export default {
  
  //公共的调接口方法
  axiosUrlNoBaseUrl: function (url, type, data, isSync, fn) {
    axios({
      method: type,
      url: url,
      data: data,
      async: isSync,
      headers: {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}
    }).then(res => {
      fn(res)
      }
    ).catch(err => {
      if (fn) {
        fn(err)
      }
      this.toast("请连接硬木课堂实验平台并开启Electronics Pioneer软件！",3000);
    })
  }
}
</script>

<style scoped>
</style>
