// get params: data
/**
 * @description get 请求 data 改为 params: data
 * @param {Object} params: data
 */
export default ({
  service,
  request
}) => ({
  /**
   * @description pc端登录
   * @param {Object} data
   */
  pcLoginApi(data = {}) {
    return request({
      url: '/auth/auth/api/pcLoginApi?route=auth', 
      method: 'post',
      data
    })
  },
  /**
   * @description pc端账号登录
   * @param {Object} data
   */
   pcUserNumLoginApi(data = {}) {
    return request({
      url: '/auth/auth/api/pcUserNumLoginApi', 
      method: 'post',
      data
    })
  },
  /**
   * @description pc端扫描二维码登录
   * @param {Object} data
   */
   pcWeChatScanQrCodeLoginApi(data = {}) {
    return request({
      url: '/auth/weChatScanLoginApi/api/pcWeChatScanQrCodeLoginApi', 
      method: 'post',
      data
    })
  },
   /**
   * @description pc端登录
   * @param {Object} data
   */
    pcLogin(data = {}) {
      return request({
        url: '/auth/auth/api/pcLogin', 
        method: 'post',
        data
      })
    },
  /**
   * @description 根据域名客户信息
   * @param {Object} data
   */
  getAppByDomainNameApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppByDomainNameApi', 
      method: 'post',
      data
    })
  },
  /**
   * @description 发送短信验证码需要图片验证码
   * @param {Object} data
   */
  doSendSmsV2(data = {}) {
    return request({
      url: '/auth/auth/api/doSendSmsV2?route=auth', 
      method: 'post',
      data
    })
  },
  /**
   * @description PC前端会员注册
   * @param {Object} data
   */
  pcRegistApi(data = {}) {
    return request({
      url: '/auth/auth/api/pcRegistApi?route=auth', 
      method: 'post',
      data
    })
  },
  /**
   * @description 忘记密码发送手机验证码
   * @param {Object} data
   */
  getResetMessageCheckCode(data = {}) {
    return request({
      url: '/member/member/api/getResetMessageCheckCode?route=member', 
      method: 'post',
      data
    })
  },
  /**
   * @description 手机验证码失效时间两分钟
   * @param {Object} data
   */
  checkPhoneCode(data = {}) {
    return request({
      url: '/member/member/api/checkPhoneCode?route=member', 
      method: 'post',
      data
    })
  },
  /**
   * @description 根据手机号码修改登录密码
   * @param {Object} data
   */
  updateUserPwd(data = {}) {
    return request({
      url: '/member/member/api/updateUserPwd?route=member', 
      method: 'post',
      data
    })
  },
  /**
   * @description 根据token获取用户信息
   * @param {Object} data
   */
  selectMemberUserInfoByToken(data = {}) {
    return request({
      url: '/member/memberSchoolUsers/api/selectMemberUserInfoByToken?route=member', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取模板底部导航
   * @param {Object} data
   */
  getAppFootNavigationApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppFootNavigationApi?route=base', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取模板导航菜单
   * @param {Object} data
   */
  getAppNavigationMenuApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppNavigationMenuApi?route=base', 
      method: 'post',
      data
    })
  },
  getArticleTypeByParentApi(params = {}) {
    return request({
      url: '/base/base/api/getArticleTypeByParentApi', 
      method: 'get',
      params
    })
  },
  /**
   * @description 文章公告列表查询
   * @param {Object} data
   */
  selectAritcleByTypeId(data = {}) {
    return request({
      url: '/base/article/api/selectAritcleByTypeId?route=base', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取客户信息
   * @param {Object} data
   */
  getAppChannelApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppChannelApi?route=base', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取模板广告
   * @param {Object} data
   */
  getAppAdContentApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppAdContentApi?route=base', 
      method: 'post',
      data
    })
  },
 /**
   * @description 根据广告位置编码查询广告内容
   * @param {Object} data
   */
  selectContentList(data = {}) {
    return request({
      url: '/base/ad/api/selectContentList', 
      method: 'post',
      data
    })
  },

  /**
   * @description 获取模板楼层
   * @param {Object} data
   */
  getAppFloorApi(data = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppFloorApi?route=base', 
      method: 'post',
      data
    })
  },  
  /**
   * @description 获取课程分类
   * @param {Object} data
   */
  getCourseTypeListApi(data = {}) {
    return request({
      url: '/train/courseFrontApi/api/getCourseTypeListApi?route=train', 
      method: 'post',
      data
    })
  },

    /**
   * @description 获取课程名称列表
   * @param {Object} data
   */
     getClientCurriculumResourceListApi(data = {}) {
      return request({
        url: '/curriculum/curriculumResourceApi/api/getClientCurriculumResourceListApi', 
        method: 'post',
        data
      })
    },
  /**
   * @description 查询所有未读数量
   * @param {Object} data
   */
  getUnReadNoticeCount(data = {}) {
    return request({
      url: '/base/channelNotice/api/getUnReadNoticeCount?route=base', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取课程列表
   * @param {Object} data
   */
  getCourseListApi(data = {}) {
    return request({
      url: '/train/courseFrontApi/api/getCourseListApi?route=train', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取课程列表
   * @param {Object} data
   */
   getClientCurriculumExperimentListApi(data = {}) {
    return request({
      url: '/curriculum/curriculumResourceApi/api/getClientCurriculumExperimentListApi', 
      method: 'post',
      data
    })
  },
  /**
   * @description 直播间列表前端直播大厅使用 无需登录token
   * @param {Object} data
   */
  queryLiveRoomNoCourseListApi(data = {}) {
    return request({
      url: '/webSocket/webSocketApi/api/queryLiveRoomNoCourseListApi', 
      method: 'post',
      data
    })
  },
  /**
   * @description 会议列表，无需登录
   * @param {Object} data
   */
  queryMeetingRoomNoCourseListApi(data = {}) {
    return request({
      url: '/webSocket/webSocketApi/api/queryMeetingRoomNoCourseListApi', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取活动分类
   * @param {Object} data
   */
  getActivityTypeListApi(data = {}) {
    return request({
      url: '/train/activityFrontApi/api/getActivityTypeListApi?route=train', 
      method: 'post',
      data
    })
  },
  /**
   * @description 获取活动列表
   * @param {Object} data
   */
  getActivityListApi(data = {}) {
    return request({
      url: '/train/activityFrontApi/api/getActivityListApi?route=train', 
      method: 'post',
      data
    })
  },
  //获取客户信息
  getAppByChannelApi(params = {}) {
    return request({
      url: '/base/channelModelApi/api/getAppByChannelApi', 
      method: 'get',
      params
    })
  },
})
