<template>
  <div>
    <el-dialog :title="title" :visible.sync="value" width="400px" :before-close="handleClose"  :close-on-press-escape="false" :close-on-click-modal="false" >
      <div class="center">
        <el-row>
          <el-col :span="24">
            <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            >
            <!-- 账号密码登录 -->
            <div v-if="isShow">
              <el-form-item label="" prop="phoneNumber">
                <el-input v-model="ruleForm.phoneNumber" placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input show-password v-model="ruleForm.password" placeholder="请输入密码" prefix-icon="el-icon-goods"></el-input>
              </el-form-item>
              <el-form-item prop="identifyCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.identifyCode" placeholder="请输入图形验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                    <img 
                    :src="codeImg"
                    @click="refreshImage"
                    height="48"
                    width="112"
                    style="cursor: pointer;width: 100%;border-radius: 4px;height: 90%"
                    >
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- <el-form-item>
                <div class="registered">
                  <el-button style="color:#606266" type="text" @click="forget">忘记密码?</el-button>
                  <el-button type="text" @click="registered">注册 ></el-button>
                </div>                
              </el-form-item> -->
              <el-form-item>
                <el-button type="primary" @click="login(1)" :loading="submitLoading" style="width:100%">确定</el-button>
              </el-form-item>
              <el-form-item>
                <div class="registered">
                  <el-button style="color:#606266" type="text" @click="noteCode">使用短信验证登录</el-button>
                  <div class="wechat" @click="weChatLogionShow">
                    <span style="margin-right:10px" >微信登录:</span>
                    <img src="@/assets/images/login/wechat.png" style="cursor: pointer">
                  </div>
                </div>                
              </el-form-item>
            </div>
            <!-- 微信登录 -->
            <div v-if="isShowWechat" style="text-align: center;">
              <wxlogin
                    appid="wx0ae8d7afe1ba9f6a"
                    :scope="'snsapi_login'"
                    :theme="'black'"
                    :redirect_uri="encodeURIComponent('https://x.acoer.cn/stu/#/index')"
                    rel="external nofollow"
                    :state="uuid"
                  >
                  </wxlogin>
               <div class="registered">
                  <el-button style="color:#606266" type="text" @click="account">使用账号登录</el-button>
                  <el-button style="color:#606266" type="text" @click="noteCode">使用短信验证登录</el-button>
               </div>
            </div>
            <!-- 短信验证码登录 -->
              <div v-if="isShowNumber">
                <el-form-item label="" prop="phoneNumber">
                  <el-input v-model="ruleForm.phoneNumber" placeholder="请输入手机号码" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <el-form-item prop="identifyCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.identifyCode" placeholder="请输入图形验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                    <img 
                    :src="codeImg"
                    @click="refreshImage"
                    height="48"
                    width="112"
                    style="cursor: pointer;width: 100%;border-radius: 4px;height: 90%"
                    >
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item prop="verificationCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.verificationCode" placeholder="请输入短信验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                   <el-button type="success" plain @click="verificationClick">
                     {{sendCodeLabel}}
                   </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
               <!-- <el-form-item> -->
                <!-- <div class="registered">
                  <el-button style="color:#606266" type="text" @click="forget">忘记密码?</el-button>
                  <el-button type="text" @click="registered">注册 ></el-button>
                </div>                 -->
              <!-- </el-form-item> -->
              <el-form-item>
                <el-button type="primary" @click="login(2)" :loading="submitLoading" style="width:100%">确定</el-button>
              </el-form-item>
                <el-form-item>
                <div class="registered">
                  <el-button style="color:#606266" type="text" @click="account">使用账号登录</el-button>
                  <div class="wechat" @click="weChatLogionShow">
                    <span style="margin-right:10px">微信登录:</span>
                    <img src="@/assets/images/login/wechat.png" style="cursor: pointer">
                  </div>
                </div>                
              </el-form-item>
              </div>
              <!-- 忘记密码 -->
              <div v-if="forgotPassword">
                <el-form-item label="" prop="phoneNumber">
                  <el-input v-model="ruleForm.phoneNumber" placeholder="请输入手机号码" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <el-form-item prop="identifyCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.identifyCode" placeholder="请输入图形验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                    <img 
                    :src="codeImg"
                    @click="refreshImage"
                    height="48"
                    width="112"
                    style="cursor: pointer;width: 100%;border-radius: 4px;height: 90%"
                    >
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item prop="verificationCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.verificationCode" placeholder="请输入短信验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                   <el-button type="success" plain @click="passwordClick">
                     {{sendCodeLabel}}
                   </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="loginForget" :loading="submitLoading" style="width:100%">确定</el-button>
              </el-form-item>
              </div>
              <!-- 注册 -->
              <div v-if="register">
                <el-form-item label="" prop="phoneNumber">
                  <el-input v-model="ruleForm.phoneNumber" placeholder="请输入手机号码" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <el-form-item prop="identifyCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.identifyCode" placeholder="请输入图形验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                    <img 
                    :src="codeImg"
                    @click="refreshImage"
                    height="48"
                    width="112"
                    style="cursor: pointer;width: 100%;border-radius: 4px;height: 90%"
                    >
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item prop="verificationCode" style="margin-bottom: 5px;">
                <el-row :gutter="8" type="flex">
                  <el-col :span="16">
                    <el-input v-model="ruleForm.verificationCode" placeholder="请输入短信验证码"></el-input>
                  </el-col>
                  <el-col :span="8" style="height: 48px">
                   <el-button type="success" plain @click="verificationClick">
                     {{sendCodeLabel}}
                   </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="" prop="password" style="margin-bottom: 5px;">
                <el-input show-password v-model="ruleForm.password" placeholder="请输入密码" prefix-icon="el-icon-goods"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="agreen">
                  <el-checkbox v-model="checked" style="color:#606266">同意</el-checkbox>
                  <el-divider direction="vertical"></el-divider>
                  <a @click="agreement">
                     <el-button type="text" >《用户注册协议》</el-button>
                  </a>
                 
                </div>                
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="loginRegistered" :loading="submitLoading" style="width:100%">确定</el-button>
              </el-form-item>
               <el-form-item>
                <div class="registered">
                  <el-button style="color:#606266" type="text" @click="accountLogin">已有账号，去登录</el-button>
                  <div class="wechat">
                    <span style="margin-right:10px">微信登录:</span>
                    <img src="@/assets/images/login/wechat.png" style="cursor: pointer">
                  </div>
                </div>                
              </el-form-item>
              </div>
              <!-- 设置新密码 -->
              <div v-if="newPassword">
                <el-form-item label="" prop="password">
                  <el-input show-password v-model="ruleForm.password" placeholder="请输入密码" prefix-icon="el-icon-goods"></el-input>
                </el-form-item>
                <el-form-item >
                  <div style="display:flex;justify-content: flex-end;">
                    <el-button type="primary" @click="previousStep" style="width:25%">上一步</el-button>
                    <el-button type="primary" @click="loginForgetNext" :loading="submitLoading" style="width:25%">确定</el-button>
                  </div>                 
                </el-form-item>
              </div>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
 
<script>
var timer = require("@/assets/timer.js");
import wxlogin from "vue-wxlogin";
import { uuid } from "vue-uuid";
export default {
   components: {
    wxlogin,
  },
  inject:['reload'],
  props: {
    value: [Boolean],
    visible: {
      type: Boolean,
      default: false
    },
    //传递的参数信息
    obejctData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    
  },
  data() {
    return {
      title: '账号登录',
      loading: false,
      ruleForm:{
        password:'', //密码
        phoneNumber:'', //手机号码
        verificationCode:'', //短信验证码
        identifyCode:'', //图形验证码
        loginType:1, //登录方式
      },
      codeImg:'', //图形验证码图片
      sendCodeLabel: "获取验证码",
      seconds: timer.length,
      interval: null,
      t:'',
      isCount:false,
      isShow: true, //账号密码登录
      isShowNumber:false, //短信验证码登录
      forgotPassword:false, //忘记密码
      register:false, //注册
      checked: true,
      isShowWechat: false,
      submitLoading: false,
      newPassword: false, //设置新密码
      sendCodeBtnDisabled:false,
      rules:{},
      uuid: uuid.v4(),
      code: this.$route.query.code ?? "",
      // 微信扫码登录参数
      appId: "wx0ae8d7afe1ba9f6a",
    }
  },
  created() {
  
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log('路由变化了')
        console.log("扫码登录");
        this.getWxScanJs();
      },
    },
  },
  mounted(){
    setTimeout(() => {
      this.refreshImage()
    }, 300)
  },
   methods: {
     getWxScanJs: function () {
      let that = this;
      that.code = that.$route.query.code ?? "";
      console.log("进入扫码登录");
      let weChatCode = that.$route.query.code ?? "";
      if ("" != weChatCode) {
        this.$router.push({ query: {}})
        this.$api
          .pcWeChatScanQrCodeLoginApi({
            appId: that.appId,
            code: weChatCode,
          })
          .then((res) => {
            if (res.code == 10008) {
              this.$alert("您还未绑定微信，请先绑定微信！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  $("#loginBtn").click();
                  localStorage.removeItem("pc_token");
                  localStorage.removeItem("pc_data");
                })
                .catch(() => {});
              return;
            } else {
              if(res.data.userType == 1){
                 this.$message({
                   message: "您当前为老师账号，不能登录学生端！",
                   type: "warning",
                });
                return false
              }
              localStorage.setItem("pc_token", res.data.token);
              that.$api
                .selectMemberUserInfoByToken({
                  token: res.data.token,
                })
                .then((re) => {
                  if (re.code == 10000) {
                   
                     this.submitLoading = false
                     this.handleClose()
                     this.reload()
                    localStorage.setItem("pc_data", JSON.stringify(re.data));
                    that.$store.dispatch("setValue", re.data);
                    let _routePath =
                      localStorage.getItem("pc_route_path") ?? "";
                    let _routeParams =
                      JSON.parse(localStorage.getItem("pc_route_query")) ?? {};
                    if (_routePath == "/index" || _routePath == "") {
                      that.$router.push({
                        path: "/index",
                        query: {},
                      });
                    } else {
                      that.$router.push({
                        path: _routePath,
                        query: _routeParams,
                      });
                    }
                  }
                });
            }
          });
      }
    },
     weChatLogionShow(){
      this.isShowWechat = true;
      this.isShow = false
      this.isShowNumber = false
      this.title = "扫码登录"
      localStorage.setItem("pc_route_path", this.$route.path);
      localStorage.setItem("pc_route_query",  JSON.stringify(this.$route.query));
     },
    handleClose() {
      this.$emit('input', false)     
      this.forgotPassword = false;
      this.register = false;
      this.isShowNumber= false;
      this.isShowWechat= false;
      this.newPassword = false;
      this.isShow = true;
      this.submitLoading = false
      this.title = '账号登录'
    },
    clear(){     
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      })          
    },
    // 切换验证码
    refreshImage(){
      this.t = this.createUniqueId(8).join("-");
      let codeImg = this.$http.httpConfig + this.$http.authImage + this.t;
      this.codeImg = codeImg
    },
    //获取图形验证码
    createUniqueId (n) {
      let random = function () {
        // 生成10-12位不等的字符串
        return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
      };
      let arr = [];

      function createId() {
        var num = random();
        var _bool = false;
        arr.forEach((v) => {
          if (v === num) _bool = true;
        });
        if (_bool) {
          createId();
        } else {
          arr.push(num);
        }
      }

      let i = 0;
      while (i < n) {
        createId();
        i++;
      }
      return arr;
    },
    // 获取短信验证码
    verificationClick(){
      let that = this;
      if (that.ruleForm.phoneNumber == "") {
        this.$message({
          message: "请输入您的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.ruleForm.phoneNumber.length != 11) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.ruleForm.identifyCode == "") {
        this.$message({
          message: "请输入图形验证码！",
          type: "warning",
        });
        return;
      }
      if (this.sendCodeBtnDisabled) {
        return;
      }
      // 禁用按钮点击
      that.sendCodeBtnDisabled = true;
      let data = {
        mobilePhone: that.ruleForm.phoneNumber,
        terminalName: 'wx',
        imgCode: that.ruleForm.identifyCode,
        t: that.t,
      }
      this.$api.doSendSmsV2(data).then((res) => {
        let data = res.data
        if(res.code == 10000){
          this.$message.success('发送成功')
          this.sendCodeLabel = --this.seconds + "s后重发";
          // 启动以1s为步长的倒计时
            that.interval = setInterval(() => {
              timer.countdown(that);
            }, 1000);
        }else{
          that.sendCodeBtnDisabled = false;
          that.refreshImage();
          this.$message.error(data.msg);
        }
      }).catch((err) => {
        that.sendCodeBtnDisabled = false;
        that.refreshImage();
        that.$message.error("网络异常，请重试");
      })
    },   
    // 忘记密码获取短信验证码
    passwordClick(){
      let that = this;
      if (that.ruleForm.phoneNumber == "") {
        this.$message({
          message: "请输入您的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.ruleForm.phoneNumber.length != 11) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
        return;
      } else if (that.ruleForm.identifyCode == "") {
        this.$message({
          message: "请输入图形验证码！",
          type: "warning",
        });
        return;
      }
      if (this.sendCodeBtnDisabled) {
        return;
      }
      // 禁用按钮点击
      that.sendCodeBtnDisabled = true;
      let data = {
        channelId: this.$http.channelId,
        mobilePhone: that.ruleForm.phoneNumber,
        checkCode: that.ruleForm.identifyCode,
        t: that.t,
        terminalName: 'pc',
      }
      this.$api.getResetMessageCheckCode(data).then(res => {
        if(res.code == 10000){
          this.$message.success('发送成功')
          this.sendCodeLabel = --this.seconds + "s后重发";
          // 启动以1s为步长的倒计时
            that.interval = setInterval(() => {
              timer.countdown(that);
            }, 1000);
        }else{
          that.sendCodeBtnDisabled = false;
          that.refreshImage();
          this.$message.error(data.msg);
        }
      }).catch(err => {
        that.sendCodeBtnDisabled = false;
        that.refreshImage();
        that.$message.error("网络异常，请重试");
      })
    },
    // 忘记密码确定按钮
    loginForget(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.submitLoading = true
          this.$api.checkPhoneCode({
            mobilePhone:this.ruleForm.phoneNumber,
            phoneCode: this.ruleForm.verificationCode,
            terminalName: 'pc',
          }).then(res => {
            if(res.code == 10000){
              this.submitLoading = false
              this.title = '设置新密码'
              this.newPassword = true
              this.forgotPassword = false
            }
          })
        }
      })
    },   
    // 设置新密码确定按钮
    loginForgetNext(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.submitLoading = true
          this.$api.updateUserPwd({
            channelId:this.$http.channelId,
            mobilePhone:this.ruleForm.phoneNumber,
            pwd: this.ruleForm.password,
          }).then(res => {
            if(res.code == 10000){
              this.$message.success('密码设置成功')
              this.submitLoading = false
              this.newPassword = false
            }
          })
        }
      })
    }, 
     // 注册确定按钮
    loginRegistered(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.submitLoading = true
          this.$api.pcRegistApi({
            channelId:this.$http.channelId,
            mobilePhone:this.ruleForm.phoneNumber,
            checkCode:this.ruleForm.verificationCode,
            imgCode:this.ruleForm.identifyCode,
            password:this.ruleForm.password,
            t:this.t,
            unionId:''
          }).then(res => {
            if(res.code == 10000){
              localStorage.setItem("pc_token", res.data.token);
              this.$message.success('注册成功')
              this.submitLoading = false
              this.refreshImage()
              this.handleClose()
              // this.value = false
            }
          }).catch(res => {
            this.ruleForm.identifyCode = ''
            this.submitLoading = false
            this.refreshImage()
          })
        }
      })
    },   
    // 账号以及短信验证码登录确定
    login(v){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.submitLoading = true
          if(v ==1){
            this.$api.pcUserNumLoginApi({
            mobilePhone:this.ruleForm.phoneNumber,
            checkCode:this.ruleForm.verificationCode,
            imgCode:this.ruleForm.identifyCode,
            loginType: v,
            password:this.ruleForm.password,
            channelId: this.$http.channelId,
            t:this.t
          }).then(res => {
            if(res.code == 10000){
              if(res.data.userType == 1){
                 this.$message({
                   message: "您当前为老师账号，不能登录学生端！",
                   type: "warning",
                });
                this.submitLoading = false
                return false
              }
              localStorage.setItem("pc_token", res.data.token);
              this.$api.selectMemberUserInfoByToken({
                token: res.data.token
              }).then(res => {
                if(res.code == 10000){
                  localStorage.setItem("pc_data", JSON.stringify(res.data));
                  this.$store.dispatch('setValue',res.data)
                }
              })
              this.$message.success('登录成功')
              this.submitLoading = false
              this.refreshImage()
              this.handleClose()
              this.reload()
              // this.value = false
              console.log("token====",localStorage.getItem("pc_token"));
            }
          }).catch(res => {
            // this.$message.error(res.msg)
            this.ruleForm.identifyCode = ''
            this.submitLoading = false
            this.refreshImage()
          })

          }else{
            //手机登录
            this.$api.pcLogin({
            mobilePhone:this.ruleForm.phoneNumber,
            checkCode:this.ruleForm.verificationCode,
            imgCode:this.ruleForm.identifyCode,
            loginType:this.ruleForm.loginType,
            password:this.ruleForm.password,
            channelId: this.$http.channelId,
            t:this.t
          }).then(res => {
            if(res.code == 10000){
              if(res.data.userType == 1){
                 this.$message({
                   message: "您当前为老师账号，不能登录学生端！",
                   type: "warning",
                });
                this.submitLoading = false
                return false
              }
              localStorage.setItem("pc_token", res.data.token);
              this.$api.selectMemberUserInfoByToken({
                token: res.data.token
              }).then(res => {
                if(res.code == 10000){
                  localStorage.setItem("pc_data", JSON.stringify(res.data));
                  this.$store.dispatch('setValue',res.data)
                }
              })
              this.$message.success('登录成功')
              this.submitLoading = false
              this.refreshImage()
              this.handleClose()
              this.reload()
              // this.value = false
              console.log("token====",localStorage.getItem("pc_token"));
            }
          }).catch(res => {
            // this.$message.error(res.msg)
            this.ruleForm.identifyCode = ''
            this.submitLoading = false
            this.refreshImage()
          })
          }
          
        }else{
          // 登录表单校验失败
          this.$message.error('表单校验失败，请检查')
        }
      })
    },  
    // 返回上一步
    previousStep(){
      this.$refs.ruleForm.resetFields();
      this.newPassword = false;
      this.forgotPassword = true
    },
    // 协议
    agreement(){
      const routeagreement = this.$router.resolve({
        name:'agreement'
      })
      window.open(routeagreement.href, '_blank')
    },
    // 注册
    registered(){
      this.title = '注册'
      this.isShow = false
      this.isShowNumber = false
      this.forgotPassword = false
      this.register = true
    },
    // 忘记密码
    forget(){
      this.title = '找回密码'
      this.isShow = false
      this.isShowNumber = false
      this.forgotPassword = true
    },

    // 使用短信验证登录
    noteCode(){
      this.ruleForm.loginType = 0
      this.isShow = false
      this.isShowNumber = true
      this.isShowWechat = false;
      this.title = "手机登录"
    },
    // 使用账号登录
    account(){
      this.ruleForm.loginType = 1
      this.isShow = true
      this.isShowWechat = false;
      this.isShowNumber = false
      this.title = "账号登录"
    },
    // 已有账号，去登陆
    accountLogin(){
      this.title = '账号登录'
      this.isShow = true
      this.isShowWechat = false;
      this.register = false
    },   
   }
}
</script>
<style lang="scss" scoped>
.registered{
  display: flex;
  justify-content: space-between;
}
.wechat{
  display: flex;
  align-items: center;
  
}
.agreen::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
  color: #606266;
}
</style>